import { useState, useEffect } from 'react';
import { AnimateSharedLayout } from 'framer-motion';
import { useLockBodyScroll } from 'react-use';
import { Container, Grid, Heading } from '@chakra-ui/layout';
import { Card } from './Card';
import portfolioData from '../../data/portfolio.json';
import Section from '../Section';

interface Props {
  selectedImage: any;
  setSelectedImage: any;
}

export function Portfolio({ selectedImage, setSelectedImage }: Props) {
  // helps in preventing the body from scrolling
  const [isScrollLocked, setScrollLocked] = useState(false);
  useLockBodyScroll(isScrollLocked);

  useEffect(() => {
    if (selectedImage !== -1) {
      setScrollLocked(true);
    } else {
      setScrollLocked(false);
    }
  }, [selectedImage]);

  return (
    <Section flexDirection="column" justifyContent="center">
      <Container>
        <Heading as="h2" mb="large">
          Here's what I've worked on
        </Heading>

        <AnimateSharedLayout>
          <Grid
            gridGap={{ base: 'medium', md: 'large' }}
            gridTemplateColumns={{
              base: '1fr',
              md: 'repeat(3, 1fr)',
            }}
            gridAutoRows={{ base: '160px', md: '240px' }}
            width="100%"
          >
            {portfolioData.sites.map((data, index) => (
              <Card
                key={`${data.name}-${index}`}
                isSelected={selectedImage === index}
                index={index}
                setSelectedImage={setSelectedImage}
                data={data}
              />
            ))}
          </Grid>
        </AnimateSharedLayout>
      </Container>
    </Section>
  );
}
