import { Link, Stack } from '@chakra-ui/layout';
import VisuallyHidden from '@chakra-ui/visually-hidden';
import { useEffect, useState } from 'react';
import { EmailIcon } from './EmailIcon';
import { GitHubIcon } from './GitHubIcon';
import { LinkedInIcon } from './LinkedInIcon';
import { TwitterIcon } from './TwitterIcon';

const Social = () => {
  const [emailLink, setEmailLink] = useState('#');
  const size = { base: '56px', sm: '72px' };

  useEffect(() => {
    setTimeout(() => setEmailLink('mailto:im@jimmymultani.com'), 1000);
  }, []);

  return (
    <Stack
      isInline
      justifyContent="center"
      spacing="medium"
      textAlign="center"
      margin="0 auto"
      mb="xlarge"
      height={size}
    >
      {[
        {
          href: emailLink,
          title: 'Email',
          color: 'brand.emailRed',
          Icon: EmailIcon,
        },
        {
          href: 'http://ca.linkedin.com/in/jimmymultani/',
          title: 'LinkedIn',
          color: 'brand.linkedinBlue',
          Icon: LinkedInIcon,
        },
        {
          href: 'https://github.com/JimmyMultani',
          title: 'GitHub',
          color: 'brand.githubGrey',
          Icon: GitHubIcon,
        },
        {
          href: 'https://twitter.com/JimmyMultani',
          title: 'Twitter',
          color: 'brand.twitterBlue',
          Icon: TwitterIcon,
        },
      ].map((item) => (
        <Link
          key={item.href}
          href={item.href}
          rel="noopener noreferrer"
          width={size}
          height={size}
          textAlign="center"
          bgColor="white"
          borderRadius="50%"
          position="relative"
          overflow="hidden"
          borderWidth={{ base: '2px', sm: '3px' }}
          border="2px solid"
          borderColor={item.color}
          transition="all 0.25s ease-in-out"
          _hover={{
            bgColor: item.color,
            borderColor: 'white',
            '> svg': {
              fill: 'white',
              transform: 'translate(-50%, -50%) scale(1.5)',
            },
          }}
        >
          <VisuallyHidden>{item.title}</VisuallyHidden>

          <item.Icon
            fill={item.color}
            width="40%"
            height="40%"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            transition="all 0.25s ease-in-out"
          />
        </Link>
      ))}
    </Stack>
  );
};

export default Social;
