import * as React from 'react';
import { Box, Container, Flex } from '@chakra-ui/layout';
import Header from './components/Header';
import Intro from './components/Intro';
import Contact from './components/Contact';
import { Portfolio } from './components/Portfolio';
import Footer from './components/Footer';
import { useColorMode } from '@chakra-ui/color-mode';
import { IconButton } from '@chakra-ui/button';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';

const App: React.FC = () => {
  const [selectedImage, setSelectedImage] = React.useState(-1);
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box className="app">
      <Header textAlign="right">
        <IconButton
          aria-label="Toggle Color Mode"
          borderRadius="50%"
          onClick={toggleColorMode}
          position="absolute"
          top="16px"
          right="16px"
          zIndex={3}
        >
          {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
        </IconButton>
      </Header>

      <Flex
        as="main"
        className="main"
        width="100%"
        minHeight="100vh"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Container>
          <Intro />
        </Container>

        <Portfolio
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
        />

        <Container>
          <Contact />
        </Container>
      </Flex>

      <Footer />
    </Box>
  );
};

export default App;
