import type { SystemStyleObject } from '@chakra-ui/theme-tools';

const baseStyle: SystemStyleObject = {
  w: '100%',
  mx: 'auto',
  maxW: '1200px',
  px: { base: 'medium', md: 'large' },
};

export const Container = {
  baseStyle,
};
