import { Box, BoxProps } from '@chakra-ui/layout';

const SvgTdaeroplanLogo = (props: BoxProps) => (
  <Box as="svg" viewBox="0 0 52 46.5" {...props}>
    <path fill="#FFF" d="M.6.6h50.9v45.3H.6z" />
    <path
      fill="#00A221"
      d="M0 0v46.5h52V0H0zm34 36.3H22V14.4h6v18h5.8c4 0 5.7-2.8 5.7-10 0-7.3-1.9-9.4-6-9.4H20.2v23.3h-6V13H5.5V9.1h29.9c7.2 0 10.6 3.8 10.6 13.2 0 12.5-5.1 14-12 14z"
    />
  </Box>
);

export default SvgTdaeroplanLogo;
