import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { Container } from './components';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: true,
};

const styles = {
  global: {
    body: {
      fontFamily: 'Open Sans, sans-serif',
      fontSize: '18px',
      lineHeight: 'base',
    },
  },
};

const breakpoints = createBreakpoints({
  sm: '600px',
  md: '768px',
  lg: '1024px',
  xl: '1440px',
});

const colors = {
  brand: {
    hanPurple: '#5f32ff',
    gunMetalGrey: '#292f36',
    frenchFuchsia: '#ff3295',
    azureBlue: '#007fff',
    refreshWhite: 'f5f5f5',
    emailRed: '#dd4b39',
    githubGrey: '#24292e',
    linkedinBlue: '#007bb6',
    twitterBlue: '#1da1f2',
  },
};

const fonts = {
  heading: 'Raleway',
  body: 'Open Sans',
};

const spacing = {
  xxsmall: '4px',
  xsmall: '8px',
  small: '12px',
  medium: '16px',
  large: '24px',
  xlarge: '32px',
  xxlarge: '48px',
};

export const theme = extendTheme({
  config,
  styles,
  breakpoints,
  colors,
  fonts,
  space: spacing,
  components: {
    Container,
  },
});
