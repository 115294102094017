import { Box, Text } from '@chakra-ui/layout';

const Footer = () => {
  return (
    <Box as="footer" padding="medium" textAlign="center">
      <Text as="small">
        ©{new Date().getFullYear()} Jimmy Multani. All rights reserved.
      </Text>
    </Box>
  );
};

export default Footer;
