import { useColorMode } from '@chakra-ui/color-mode';
import { Box } from '@chakra-ui/layout';
import { MotionBox } from '../Motion';

const ScrollDown = () => {
  const { colorMode } = useColorMode();
  const color =
    colorMode === 'dark' ? 'brand.frenchFuchsia' : 'brand.hanPurple';

  return (
    <Box
      display={{ base: 'none', md: 'block' }}
      position="absolute"
      width="48px"
      height="48px"
      left="50%"
      bottom="24px"
      transform="translateX(-50%)"
    >
      <MotionBox
        width="100%"
        height="100%"
        border="1px solid"
        borderColor={color}
        borderRadius="50%"
        position="absolute"
        top={0}
        left={0}
        animate={{
          scale: [0.5, 1.2],
          opacity: [0, 1, 0],
        }}
        transition={{
          duration: 1.8,
          ease: 'easeInOut',
          repeat: Infinity,
          repeatType: 'loop',
        }}
      />

      <Box
        className="icon-arrow"
        position="absolute"
        top="50%"
        left="50%"
        width="12px"
        height="12px"
        border="2px solid"
        borderColor={color}
        borderTop="none"
        borderLeft="none"
        mt="-3px"
        transform="translate(-50%, -50%) rotate(45deg)"
      />
    </Box>
  );
};

export default ScrollDown;
