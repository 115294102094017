import { Box, BoxProps } from '@chakra-ui/layout';

interface Props extends BoxProps {}

const Header = ({ children, ...rest }: Props) => {
  return (
    <Box as="header" {...rest}>
      {children}
    </Box>
  );
};

export default Header;
