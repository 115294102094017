import { BoxProps, Flex } from '@chakra-ui/layout';

interface Props extends BoxProps {}

function Section({ children, ...rest }: Props): JSX.Element {
  return (
    <Flex
      as="section"
      flexWrap={{ base: 'wrap', md: 'nowrap' }}
      width="100%"
      minHeight="100vh"
      justifyContent={{ md: 'center' }}
      alignItems={{ md: 'center' }}
      {...rest}
    >
      {children}
    </Flex>
  );
}

export default Section;
