import { Container, Heading } from '@chakra-ui/layout';
import Section from '../Section';
import Social from '../Social';

function Contact() {
  return (
    <Section id="contact" alignItems="center">
      <Container>
        <Heading as="h2" mb="large" textAlign="center">
          You can contact me through
        </Heading>
        <Social />
      </Container>
    </Section>
  );
}

export default Contact;
