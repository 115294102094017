import Section from '../Section';
import ScrollDown from '../ScrollDown';
import avatar from '../../images/me.jpg';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Image,
  Link,
  useColorModeValue,
} from '@chakra-ui/react';
import { MotionBox } from '../Motion';

const Intro = () => {
  const linkColor = useColorModeValue('purple.600', 'purple.200');
  const size = { base: '240px', md: '400px' };

  return (
    <Section id="intro" py="xlarge" position="relative">
      <Box order={{ base: 2, md: 1 }} paddingRight={{ md: 'large' }}>
        <Heading as="h1" mb="large">
          Hey there! 👋
        </Heading>

        <Text mb="medium">
          My name is Jimmy, and I work at{' '}
          <Link href="https://rakuten.com" isExternal color={linkColor}>
            Rakuten Rewards <ExternalLinkIcon ml="2px" />
          </Link>{' '}
          as a Staff Software Engineer.
        </Text>

        <Text mb="medium">
          I hail from Toronto, Ontario. Born and raised! 🇨🇦
        </Text>

        <Text fontWeight="semibold" mb="xsmall">
          🔭 I&apos;m currently working on:
        </Text>
        <UnorderedList mb="medium">
          <ListItem>Serverless / Lambda functions 🐑</ListItem>
          <ListItem>SSR with React Query and NextJS ⚛️</ListItem>
          <ListItem>Writing ExpessJS APIs ⏩</ListItem>
        </UnorderedList>

        <Text fontWeight="semibold" mb="xsmall">
          💬 Here are some things about me:
        </Text>
        <UnorderedList>
          <ListItem>I love playing video games 🎮</ListItem>
          <ListItem>
            I'm still learning how to use my Ergo keyboards ⌨️
          </ListItem>
          <ListItem>I have a son and partner, whom I love dearly ❤️</ListItem>
          <ListItem>I love to socialize with my crew 🍻</ListItem>
        </UnorderedList>
      </Box>

      <Box
        order={{ base: 1, md: 2 }}
        mb={{ base: 'xlarge', md: 0 }}
        ml={{ md: 'large' }}
        flex={{ base: '0 0 240px', md: '0 0 400px' }}
        mx="auto"
      >
        <Box position="relative">
          <MotionBox
            width={size}
            height={size}
            bgGradient="linear(to-r, brand.azureBlue, brand.frenchFuchsia, brand.hanPurple)"
            borderRadius="50%"
            position="absolute"
            top="calc(-1 * var(--chakra-space-xsmall))"
            left="calc(-1 * var(--chakra-space-xsmall))"
            zIndex={-1}
            animate={{
              rotate: [0, 360],
            }}
            transition={{
              duration: 3,
              ease: 'linear',
              repeat: Infinity,
              repeatType: 'loop',
            }}
          />

          <Image
            src={avatar}
            alt="A picture of me!"
            display="block"
            width={{
              base: `calc(240px - var(--chakra-space-xsmall) * 2)`,
              md: 'calc(400px - var(--chakra-space-xsmall) * 2)',
            }}
            height={{
              base: 'calc(240px - var(--chakra-space-xsmall) * 2)',
              md: 'calc(400px - var(--chakra-space-xsmall) * 2)',
            }}
            borderRadius="50%"
          />
        </Box>
      </Box>

      <ScrollDown />
    </Section>
  );
};

export default Intro;
