import { Box, BoxProps } from '@chakra-ui/layout';

const SvgTdhousieLogo = (props: BoxProps) => (
  <Box as="svg" viewBox="0 0 173.7 46.5" {...props}>
    <path fill="#FFF" d="M.6.6h50.9v45.3H.6z" />
    <path
      fill="#00A221"
      d="M0 0v46.5h52V0H0zm34 36.3H22V14.4h6v18h5.8c4 0 5.7-2.8 5.7-10 0-7.3-1.9-9.4-6-9.4H20.2v23.3h-6V13H5.5V9.1h29.9c7.2 0 10.6 3.8 10.6 13.2 0 12.5-5.1 14-12 14z"
    />
    <g
    // fill="#0F3E26"
    >
      <path d="M70.9 20.6H74l-.5 3.8h-3.1l.5-3.8zm4.5-9.2l-.9 6h-3.1l.9-6h-3.1l-.9 6h-3.4v3.2h2.9l-.5 3.8H64v3.2h2.8l-.9 6.1H69l.9-6.1H73l-1 6.1h3.1l.9-6.1h3.4v-3.2h-2.8l.5-3.8h3.2v-3.2h-2.7l.9-6h-3.1zm7.6.1h4.8v8.8h.1c.5-.7 1.1-1.2 1.9-1.6.7-.4 1.6-.6 2.5-.6 3 0 5.4 2.1 5.4 6.7v9h-4.8v-8.4c0-2-.7-3.4-2.5-3.4-1.3 0-2 .8-2.4 1.7-.1.3-.2.7-.2 1.1v9.1H83V11.5zm33.5 14.4c0 5.6-4 8.2-8.1 8.2-4.5 0-7.9-2.9-7.9-7.9s3.3-8.1 8.2-8.1c4.7-.1 7.8 3.2 7.8 7.8m-11.1.1c0 2.6 1.1 4.6 3.1 4.6 1.9 0 3-1.9 3-4.6 0-2.3-.9-4.6-3-4.6-2.2 0-3.1 2.4-3.1 4.6M133.9 28.8c0 2 .1 3.6.1 4.9h-4.1l-.2-2.2h-.1c-.6.9-2 2.5-4.8 2.5-3.1 0-5.4-1.9-5.4-6.7v-9h4.8v8.2c0 2.2.7 3.6 2.4 3.6 1.3 0 2.1-.9 2.4-1.7.1-.3.2-.7.2-1v-9.1h4.8l-.1 10.5zm3.8.8c.9.5 2.7 1.2 4.1 1.2 1.4 0 2-.5 2-1.3s-.5-1.2-2.3-1.8c-3.2-1.1-4.4-2.8-4.4-4.6 0-2.9 2.4-5 6.2-5 1.8 0 3.4.4 4.3.9l-.8 3.3c-.7-.4-2-.9-3.3-.9-1.2 0-1.8.5-1.8 1.3 0 .7.6 1.1 2.5 1.8 2.9 1 4.1 2.5 4.2 4.7 0 2.9-2.3 5-6.6 5-2 0-3.8-.4-5-1.1l.9-3.5zm18.6-15.5c0 1.3-1 2.4-2.6 2.4-1.5 0-2.5-1.1-2.5-2.4 0-1.4 1-2.4 2.5-2.4 1.6 0 2.6 1 2.6 2.4m-4.9 4.3h4.8v15.3h-4.8V18.4zm12.2 9.2c.2 2 2.1 2.9 4.3 2.9 1.6 0 2.9-.2 4.2-.6l.6 3.2c-1.6.6-3.5.9-5.6.9-5.2 0-8.2-3-8.2-7.8 0-3.9 2.4-8.2 7.7-8.2 5 0 6.8 3.9 6.8 7.7 0 .8-.1 1.5-.2 1.9h-9.6zm5.7-3.3c0-1.2-.5-3.1-2.7-3.1-2 0-2.8 1.8-2.9 3.1h5.6z" />
    </g>
  </Box>
);

export default SvgTdhousieLogo;
