import { Box, BoxProps } from '@chakra-ui/layout';

const SvgLogoFullDark = (props: BoxProps) => (
  <Box as="svg" viewBox="0 0 113 114" {...props}>
    <g fill="none" fillRule="evenodd" transform="translate(0 -1)">
      <path
        fill="#E3F4FC"
        fillRule="nonzero"
        d="M101.76,93.75 C69.76,147.39 -35.09,86.75 11.95,26.37 C60.47,-35.9 141,28 101.76,93.75 Z"
      />
      <path
        fill="#211A53"
        d="M85.2992253,62.195844 L39.4092253,62.195844 C41.0392253,72.265844 48.8092253,78.195844 59.4692253,78.195844 C65.8592253,78.195844 71.8792253,75.835844 77.3992253,70.985844 L83.6692253,79.985844 C77.101301,86.381412 68.2659864,89.912661 59.0992253,89.805844 C49.5692253,89.805844 41.6692253,86.805844 35.4092253,80.725844 C29.2111754,74.8010166 25.7983391,66.5375959 26.0092253,57.965844 C26.0092253,48.965844 29.0092253,41.425844 35.1592253,35.335844 C41.1003673,29.2183558 49.313191,25.8398 57.8392253,26.005844 C65.9892253,26.005844 72.7592253,28.615844 78.1492253,34.005844 C83.5299712,39.2483786 86.4832419,46.4956687 86.2992253,54.005844 C86.2596123,56.7637415 85.9243622,59.5094392 85.2992253,62.195844 Z M39.6592253,51.995844 L73.1392253,51.995844 C73.0092253,42.915844 66.2392253,37.075844 57.3392253,37.075844 C48.6892253,37.075844 41.6692253,42.905844 39.6592253,51.995844 Z"
      />
    </g>
  </Box>
);

export default SvgLogoFullDark;
