import { Box, BoxProps } from '@chakra-ui/layout';

export function EmailIcon(props: BoxProps) {
  return (
    <Box as="svg" viewBox="0 0 1000 1000" {...props}>
      <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
        <path d="M1232.5,3694.7c-465.2-26.9-813.1-253.7-1016.8-665.1c-123-248-119.2-107.6-113.4-2979.4l5.8-2610.3l51.9-144.2c124.9-340.2,403.7-617,742-734.3l128.8-44.2h3979h3979l132.6,51.9c349.8,142.2,601.6,397.9,726.6,745.8l44.2,125l5.8,2610.3c5.8,2871.8,9.6,2733.4-113.4,2983.3c-155.7,317.2-438.3,545.9-782.3,634.3c-109.6,28.8-486.3,32.7-3844.4,34.6C3110.5,3704.3,1344,3700.4,1232.5,3694.7z M8419.6,2779.7c-1.9-26.9-3337-2379.7-3367.7-2377.8C5025,403.9,1886,2766.2,1886,2785.5c0,3.8,1470.5,7.7,3267.7,7.7C6951,2793.2,8421.5,2787.4,8419.6,2779.7z M3024,765.2C4121.6-65.2,5026.9-743.7,5036.5-743.7c11.5,0,899.6,626.7,1976,1389.8l1956.8,1391.7l5.8-2222.1c3.8-2122.1,1.9-2224-30.8-2268.2c-19.2-26.9-59.6-65.4-88.4-86.5l-51.9-40.4H5000H1196l-51.9,40.4c-28.8,21.1-71.1,63.4-92.3,92.3c-40.4,51.9-40.4,63.4-46.1,2387.4c-1.9,1284,1.9,2333.6,9.6,2333.6C1024.9,2274.2,1928.3,1595.6,3024,765.2z" />
      </g>
    </Box>
  );
}
