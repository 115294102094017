import ReactDOM from 'react-dom';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import '@fontsource/raleway/800.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/700.css';
import App from './App';
import { theme } from './theme';

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <App />
  </ChakraProvider>,
  document.getElementById('root')
);
