import { Box, Flex, Grid, Heading, Text } from '@chakra-ui/layout';
import { MotionBox } from '../Motion';
import { Image } from '@chakra-ui/image';
import { CloseButton } from '@chakra-ui/close-button';
import { Tag } from '@chakra-ui/tag';
import { useColorModeValue } from '@chakra-ui/color-mode';

// animation config and variants
const spring = {
  type: 'spring',
  stiffness: 500,
  damping: 30,
};

const backVariants = {
  initial: {
    opacity: 0,
    y: -20,
  },
  animate: { opacity: 1, y: 0 },
};

const cardVariants = {
  initial: {
    opacity: 0,
    y: 100,
    x: '-50%',
  },
  animate: { opacity: 1, y: 0, x: '-50%' },
};

interface CardProps {
  isSelected: any;
  setSelectedImage: (value: any) => void;
  index: number;
  data: Record<string, any>;
}

export function Card({ isSelected, setSelectedImage, index, data }: CardProps) {
  const bgColor = useColorModeValue('white', 'gray.800');
  const Logo = require(`../Logos/${data.logo}`).default;
  const bgImg = require(`../../images/${data.key}-front.jpg`);

  const goBack = () => {
    setSelectedImage(-1);
  };

  return (
    <MotionBox layoutId={`card-container--index-${index}`} transition={spring}>
      <MotionBox
        onClick={() => {
          setSelectedImage(index);
        }}
        layoutId={`card-image--index-${index}`}
        width="100%"
        height="100%"
        overflow="hidden"
        {...(isSelected
          ? {
              position: 'fixed',
              zIndex: 2,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              borderRadius: 0,
              cursor: 'unset',
            }
          : { borderRadius: '12px', cursor: 'pointer', position: 'relative' })}
      >
        <Image
          src={bgImg}
          alt={(data.name as string) ?? ''}
          filter="brightness(0.2)"
          width="100%"
          height="100%"
          objectFit="cover"
        />
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          transition="opacity 250ms"
          {...(isSelected ? { opacity: 0 } : { opacity: 1 })}
        >
          <Logo
            fill="white"
            width="100%"
            height={{ base: '48px', md: '64px' }}
          />
        </Box>
      </MotionBox>

      {isSelected && (
        <MotionBox
          initial="initial"
          animate="animate"
          exit="initial"
          transition={{ delay: 0.1, duration: 0.5 }}
          variants={cardVariants}
          position="fixed"
          bottom={{ base: 0 }}
          left="50%"
          transform="translateX(-50%)"
          width="100%"
          height="100%"
          padding={{ base: 'medium', md: 'xxlarge' }}
          zIndex={3}
        >
          <Box
            backgroundColor={bgColor}
            height={{ base: 'calc(100% - 32px)', md: '100%' }}
            padding={{ base: 'medium', md: 'xlarge' }}
            borderRadius="12px"
            overflow="auto"
            mt={{ base: '32px', md: 0 }}
          >
            <Box alignItems="center" mb="medium" textAlign="center">
              <Logo
                height={{ base: '48px', md: '48px' }}
                fill="white"
                margin="0 auto"
              />
              <Heading as="h3" size="md" my="xlarge">
                {data.name}
              </Heading>

              <Text mb="medium">{data.text}</Text>

              <Text mb="medium">
                While working at{' '}
                <Text as="span" fontWeight="bold">
                  {data.context}
                </Text>
              </Text>

              <Flex flexWrap="wrap" justifyContent="center" mb="xlarge">
                {data.tools.map((tool: string) => (
                  <Tag
                    key={tool}
                    colorScheme="purple"
                    size="md"
                    margin="xxsmall"
                    mt={0}
                  >
                    {tool}
                  </Tag>
                ))}
              </Flex>
            </Box>

            {data.images?.length === 0 && (
              <Text textAlign="center">
                Mockup images are not ready yet. Check back soon!
              </Text>
            )}

            {data.images === null && (
              <Text textAlign="center">
                Sorry, but I'm unable to display mockups due to contract
                obligations.
              </Text>
            )}

            {data.images?.length > 0 && (
              <Grid
                gridTemplateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
                gap={{ base: 'medium', md: 'large' }}
              >
                {data.images?.map((image: string) => {
                  const img = require(`../../images/${data.key}/${image}`);

                  return (
                    <Box key={image}>
                      <Image src={img} alt="" />
                    </Box>
                  );
                })}
              </Grid>
            )}
          </Box>
        </MotionBox>
      )}

      {isSelected && (
        <MotionBox
          initial="initial"
          animate="animate"
          exit="initial"
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={backVariants}
          position="fixed"
          top={0}
          right={0}
          zIndex={3}
        >
          <CloseButton
            onClick={goBack}
            width="48px"
            height="48px"
            colorScheme="dark"
          />
        </MotionBox>
      )}
    </MotionBox>
  );
}
