import { Box, BoxProps } from '@chakra-ui/layout';

const SvgOandaLogo = (props: BoxProps) => (
  <Box as="svg" viewBox="0 0 388.7 79.5" {...props}>
    <path d="M218.8 11.7h6.1L258 59.9h.2V11.7h5.4v56.9h-6.1l-33.2-48.2h-.2v48.2h-5.4V11.7zm-8.9 57h5.8l-22.3-56.9h-6.1l-23 56.9h5.8l4.9-12.5h30l4.9 12.5zm-33-17.2l13.2-33.9 13.1 33.9h-26.3zm-40.1-41.1c-17 0-28.9 13.3-28.9 29.6s12 29.6 28.9 29.6c17.2 0 29-13.3 29-29.6 0-16.4-12.1-29.6-29-29.6zm0 54.2c-14.1 0-24.1-11-24.1-24.7 0-13.6 9.9-24.7 24.1-24.7 14.1 0 24.2 11 24.2 24.7s-9.9 24.7-24.2 24.7zM271 11.7h18.1c17.1.4 28.6 13.6 28.6 29.4 0 14.7-11.5 27.5-28.6 27.5H271V11.7zm5.4 52h12.4c15.4 0 23.8-11.7 23.8-22.9 0-11.9-7.6-24.2-23.8-24.2h-12.4v47.1zm94 5h6.5l-47.8-56.9h-5.5v56.9h5.4V56.2h31l10.4 12.5zM329 51.5V20h.5L356 51.5h-27z" />
    <path
      d="M35.5 72.1c3.1.9 6.3 1.4 9.7 1.4 7 0 13.6-2.2 19-5.9l-28.7-34v38.5zM11.6 40c0 9.2 3.7 17.5 9.6 23.5v-47c-6 6-9.6 14.3-9.6 23.5zm23.9-28.3l38.4 45.7c3.1-5.1 4.9-11.1 4.9-17.5 0-18.5-15-33.6-33.6-33.6-6.7 0-12.9 2-18.1 5.3l8.4.1z"
      fill="#97d700"
    />
  </Box>
);

export default SvgOandaLogo;
